import { Stack } from "react-bootstrap";

export const StarRating = ({
  className = "flex-row justify-content-center gap-2 mt-4 mb-5",
  value = 4,
}) => {
  const rating = new Array(5)
    .fill(0)
    .map((_, i) =>
      i + 1 <= value ? (
        <img src="/assets/FullStar.svg" alt="" key={i} />
      ) : i + 0.5 <= value ? (
        <img src="/assets/HalfStar.svg" alt="" key={i} />
      ) : (
        <img src="/assets/EmptyStar.svg" alt="" key={i} />
      ),
    );

  return <Stack className={className}>{rating}</Stack>;
};
