import { StarRating } from "@/ui/StarRating";
import cn from "classnames";
import { Card } from "react-bootstrap";
import profile_pic from "./profile_pic.webp";

import classes from "./styles.module.scss";

export default function ({ className = "px-4 py-3", title, name, content, rating, image = null }) {
  return (
    <Card className={cn(className, classes.ReviewCard)} data-bs-theme="dark">
      {image && (
        <Card.Img as="div" className="py-2">
          <img src={image.length > 0 ? image : profile_pic} alt="" />
        </Card.Img>
      )}
      <Card.Body>
        {rating && <StarRating value={rating} />}
        <Card.Text as="div" className="mb-4" dangerouslySetInnerHTML={{ __html: content }} />
        <Card.Title as="h6" dangerouslySetInnerHTML={{ __html: title }} />
        <Card.Subtitle as="p" dangerouslySetInnerHTML={{ __html: name }} />
      </Card.Body>
    </Card>
  );
}
